th {
  color: hsl(217, 71%, 53%) !important;
  user-select: none;
}
thead > tr > th:hover {
  cursor: pointer !important;
  background-color: #fafafa;
}
.tableContainer {
  width: 100%;
  overflow-x: auto;
}

td > a {
  display: block;
  color: unset;
}

.sorted-desc::after {
  content: '\25b4';
}

.sorted-asc::after {
  content: '\25be';
}

.container {
  margin: 0 5%;
}

button#all-courses {
  display: block;
  background-color: transparent;
  border: none;
  padding: 0;
  color: #3273dc;
  cursor: pointer;
  text-decoration: none;
  font-size: inherit;
  line-height: inherit;
}

a#all-courses {
  display: block;
}

.container > *:not(#info) {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

footer {
  text-align: center;
}

.control {
  display: block;
  width: fit-content;
  margin: auto;
}

.control-group {
  display: inline-block;
}

.control-group:nth-child(2) {
  margin: 0 0.5em;
  padding: 0 0.5em;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
}

.recharts-tooltip-item > span {
  color: #4a4a4a;
}

.recharts-tooltip-item::before {
  content: '\2B24 ';
}